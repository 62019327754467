<template>
  <div>
    <div class="my-footer">
      <div class="prev" v-if="!isFirst" @click="prev">上一题</div>
      <div class="options">
        <ul>
          <li
            v-for="item in optionsList"
            :key="item.key"
            :class="{active: item.active}"
            @click="itemClick(item)"
          >{{ item.content }}</li>
        </ul>
      </div>
      <div class="next" @click="next">下一题</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myFooter',
  props: {
    isFirst: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      optionsList: []
    }
  },
  watch: {
    options: {
      handler(val) {
        this.optionsList = JSON.parse(JSON.stringify(this.options))
        this.$emit('optionChange', this.optionsList)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    itemClick(item) {
      if (this.type === 1) {
        this.optionsList.forEach(e => {
          e.active = false
        })
      }
      item.active = !item.active
      this.$emit('optionChange', this.optionsList)
    },
    prev() {
      this.$emit('prev', this.optionsList)
    },
    next() {
      this.$emit('next', this.optionsList)
    },
  }
}
</script>

<style lang="less" scoped>
.my-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  min-height: 0.7rem;
  box-shadow: 0px 0px 6px 1px rgba(157, 4, 4, 0.16);
  padding: 0.1rem 0.2rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  .prev,
  .next {
    width: 0.96rem;
    height: 0.34rem;
    line-height: 0.34rem;
    border-radius: 0.17rem;
    border: 1px solid #ff8a88;
    text-align: center;
    font-size: 0.16rem;
    color: #666;
    cursor: pointer;
    box-sizing: border-box;
  }
  .options {
    flex: 1;
    ul {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding: 0 0.1rem;
    }
    li {
      height: 0.34rem;
      line-height: 0.34rem;
      background: #ffffff;
      border-radius: 0.17rem;
      border: 1px solid #dfdfdf;
      padding: 0 0.14rem;
      font-size: 0.15rem;
      color: #808080;
      box-sizing: border-box;
      margin: 0.05rem;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background: #ffaa20;
        border: 1px solid #ffaa20;
        color: #fff;
      }
    }
  }
}
</style>