<template>
  <div>
    <my-title
      :tag="info.main_name + '-' + info.sta_name"
      :title="info.name"
      :tip="info.norm"
      :type="info.is_duo == 1 ? 2 : 1"
    ></my-title>
    <div class="startAnswer">
      <van-button color="#F14947" @click="startAnswer">开始答题</van-button>
    </div>
  </div>
</template>

<script>
import myTitle from "./title.vue";
export default {
  name: "exercise",
  components: {
    myTitle,
  },
  props: {
    initData: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      info: {},
      list: [],
    };
  },
  watch: {
    initData: {
      handler(val) {
        this.info = JSON.parse(JSON.stringify(val));
      },
      deep: true,
      immediate: true
    },
    list: {
      handler(val) {
        if (val.length > 0) {
          this.$emit('countChange', this.count)
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    count() {
      // 整理列表中各选项的数量
      let a = this.list.filter(e => e.option * 1 === 1).length
      let b = this.list.filter(e => e.option * 1 === 2).length
      let c = this.list.filter(e => e.option * 1 === 3).length
      let d = this.list.filter(e => e.option.length === 0).length
      let arr = [
        { type: 1, name: '接受', num: a },
        { type: 2, name: '勉强接受', num: b },
        { type: 3, name: '抗拒', num: c },
        { type: 4, name: '未答', num: d },
      ]
      return arr
    }
  },
  created() {
    this.getTopicList();
  },
  methods: {
    // 获取子题目列表
    async getTopicList() {
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        lesson_id: this.$route.query.lesson_id,
        manor_id: this.$route.query.manor_id,
        main_id: this.$route.query.main_id,
        stage_id: this.$route.query.stage_id,
        kqtopic_id: this.$route.query.id,
      };
      let res = await this.$http.get('pad/oral-snag/son-num', { params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.list = res.data instanceof Array ? res.data : [];
    },
    // 进入子题
    async getEnterTopic(trecord_id) {
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        lesson_id: this.$route.query.lesson_id,
        manor_id: this.$route.query.manor_id,
        main_id: this.$route.query.main_id,
        stage_id: this.$route.query.stage_id,
        kqtopic_id: this.$route.query.id,
      };
      let res = await this.$http.get('pad/oral-snag/son-enter', { params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.$router.replace({
        name: 'oralTopic',
        query: {
          uid: this.$route.query.uid,
          review_id: this.$route.query.review_id,
          ass_time: this.$route.query.ass_time,
          time: this.$route.query.time,
          lesson_id: this.$route.query.lesson_id,
          manor_id: this.$route.query.manor_id,
          main_id: this.$route.query.main_id,
          stage_id: this.$route.query.stage_id,
          id: this.$route.query.id,
          mailtrec_id: this.$route.query.mailtrec_id,
          trecord_id,
          topidrill_id: res.data.id
        }
      })
    },
    async startAnswer() {
      if (this.list.length == 0) {
        return this.$vantnotify('暂无子题目');
      }
      let params = {
        uid: this.$route.query.uid,
        mailtrec_id: this.$route.query.mailtrec_id,
        review_id: this.$route.query.review_id,
        lesson_id: this.info.lesson_id,
        manor_id: this.info.manor_id,
        main_id: this.info.main_id,
        stage_id: this.info.stage_id,
        num: this.info.num,
        kqtopic_id: this.info.id,
        type: this.info.type,
      }
      const res = await this.$http.post('kq-trecord/initial', params);
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.getEnterTopic(res.data.id)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  margin-bottom: 0.16rem;
}
.startAnswer {
  margin-top: 1rem;
  text-align: center;
  button {
    height: 0.38rem;
    border-radius: 0.06rem;
    padding: 0 0.28rem;
    font-size: 0.16rem;
  }
}
</style>