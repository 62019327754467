<template>
  <div>
    <my-title :tag="info.main_name + '-' + info.sta_name" :title="info.name" :tip="info.norm"></my-title>
    <div class="record-box">
      <template v-if="info.seat_type * 1 === 1">
        <my-record
          class="single"
          numKey="ado_num"
          :initObj="handleSingleData"
          :duration="info.duration * 1"
          @statusChange="statusChange"
          @start="startRecording"
          @stop="stopRecording"
        ></my-record>
      </template>
      <template v-if="info.seat_type * 1 === 2">
        <my-record
          class="double"
          numKey="left_num"
          directionTxt="左侧"
          :initObj="handleDobleData.left"
          :duration="info.duration * 1"
          @statusChange="statusChange"
          @start="startRecording"
          @stop="stopRecording"
        ></my-record>
        <my-record
          class="double"
          numKey="right_num"
          directionTxt="右侧"
          :initObj="handleDobleData.right"
          :duration="info.duration * 1"
          @statusChange="statusChange"
          @start="startRecording"
          @stop="stopRecording"
        ></my-record>
      </template>
      <template v-if="info.seat_type * 1 === 3">
        <my-record
          class="three"
          numKey="left_num"
          directionTxt="左侧"
          :initObj="handleThreeData.left"
          :duration="info.duration * 1"
          @statusChange="statusChange"
          @start="startRecording"
          @stop="stopRecording"
        ></my-record>
        <my-record
          class="three"
          numKey="right_num"
          directionTxt="右侧"
          :initObj="handleThreeData.right"
          :duration="info.duration * 1"
          @statusChange="statusChange"
          @start="startRecording"
          @stop="stopRecording"
        ></my-record>
        <my-record
          class="three"
          numKey="both_num"
          directionTxt="两侧"
          :initObj="handleThreeData.both"
          :duration="info.duration * 1"
          @statusChange="statusChange"
          @start="startRecording"
          @stop="stopRecording"
        ></my-record>
      </template>
    </div>
    <my-video v-if="info.video_url" :src="info.video_url"></my-video>
    <div class="comment" @click="$emit('popupVisible')">{{ info.topscu_id > 0 ? '查看评论' : '添加评论'}}</div>
  </div>
</template>

<script>
import myTitle from "./title.vue";
import myVideo from "./video.vue";
import myRecord from "./timeCountRecord.vue";
export default {
  name: "timeCounting",
  components: {
    myTitle,
    myVideo,
    myRecord
  },
  props: {
    initData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      info: {},
      // 单侧数据
      singleData: [
        { ado_num: 0, list: [] }
      ],
      // 双侧数据
      dobleData: [
        { left_num: 0, list: [] },
        { right_num: 0, list: [] }
      ],
      // 三侧数据
      threeData: [
        { left_num: 0, list: [] },
        { right_num: 0, list: [] },
        { both_num: 0, list: [] }
      ],
      currentRecording: null, // 存储当前正在计时的子组件的引用
    };
  },
  watch: {
    initData: {
      handler(val) {
        this.info = JSON.parse(JSON.stringify(val));
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 预处理各类型记录数据
    handleSingleData() {
      return this.singleData.find(item => 'ado_num' in item);
    },
    handleDobleData() {
      return {
        left: this.dobleData.find(item => 'left_num' in item),
        right: this.dobleData.find(item => 'right_num' in item)
      };
    },
    handleThreeData() {
      return {
        left: this.threeData.find(item => 'left_num' in item),
        right: this.threeData.find(item => 'right_num' in item),
        both: this.threeData.find(item => 'both_num' in item)
      };
    },
    // 是否通过
    isPass() {
      let flag = false;
      if (this.info.seat_type * 1 === 1) {
        flag = this.handleSingleData.ado_num >= this.info.tally_num;
      }
      if (this.info.seat_type * 1 === 2) {
        flag = this.handleDobleData.left.left_num >= this.info.tally_num && this.handleDobleData.right.right_num >= this.info.tally_num;
      }
      if (this.info.seat_type * 1 === 3) {
        flag = this.handleThreeData.left.left_num >= this.info.tally_num && this.handleThreeData.right.right_num >= this.info.tally_num && this.handleThreeData.both.both_num >= this.info.tally_num;
      }
      return flag
    }
  },
  created() {
    if (this.info.seat_type * 1 === this.info.rec_info.seat_type * 1) {
      switch (this.info.seat_type * 1) {
        case 1:
          this.$set(this, 'singleData', this.info.rec_info.train_rec)
          break;
        case 2:
          this.$set(this, 'dobleData', this.info.rec_info.train_rec)
          break;
        case 3:
          this.$set(this, 'threeData', this.info.rec_info.train_rec)
          break;
        default:
          break;
      }
    }
  },
  methods: {
    statusChange() {
      this.$emit('statusChange', this.isPass);
    },
    startRecording(component) {
      // 如果当前有正在计时的组件，并且不是即将开始的这个组件，则停止它
      if (this.currentRecording && this.currentRecording !== component) {
        this.currentRecording.stopTimer();
      }
      // 设置当前正在计时的组件
      this.currentRecording = component;
      // 开始计时
      component.startTimer();
    },
    stopRecording() {
      if (this.currentRecording) {
        this.currentRecording = null;
      }
    },
  },
  beforeDestroy() {
    if (this.currentRecording) {
      this.currentRecording.stopTimer();
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  margin-bottom: 0.16rem;
}
.comment {
  position: fixed;
  bottom: 1.5rem;
  right: 0.25rem;
  font-size: 0.14rem;
  color: #ff7170;
  background: #fffbfb;
  width: 0.62rem;
  height: 0.62rem;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px #ffc4c4;
  border: 1px solid #ff8a88;
  text-align: center;
  padding: 0.11rem 0.15rem;
  cursor: pointer;
  box-sizing: border-box;
}
.record-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0.2rem;
}

.single {
  width: 100%;
}
.double {
  width: 49%;
}
.three {
  width: 32%;
}
@media only screen and (orientation: portrait) {
  .three {
    width: 49%;
    margin-bottom: 0.16rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>