<template>
  <div>
    <my-title
      :tag="sonInfo.main_name + '-' + sonInfo.sta_name"
      :title="sonInfo.title"
      :tip="sonInfo.norm"
    ></my-title>
    <my-video v-if="sonInfo.video_url" :src="sonInfo.video_url"></my-video>
    <div v-else class="video-occupy"></div>
    <div class="options">
      <ul>
        <li
          v-for="(item,index) in sonInfo.option"
          :key="item.key"
          :class="{active: item.active}"
          @click="itemClick(item,index)"
        >{{ item.content }}</li>
      </ul>
    </div>
    <div class="btns">
      <van-button plain color="#FE6765" native-type="submit" v-if="topicIndex > 0" @click="prev">上一题</van-button>
      <van-button
        color="#FE6765"
        native-type="submit"
        v-if="topicIndex < list.length - 1"
        @click="next"
      >下一题</van-button>
    </div>
    <div class="comment" @click="$emit('popupVisible')">{{ sonInfo.topscu_id > 0 ? '查看评论' : '添加评论'}}</div>
  </div>
</template>

<script>
import myTitle from "./title.vue";
import myVideo from "./video.vue";
export default {
  name: "sonTopic",
  components: {
    myTitle,
    myVideo
  },
  props: {
    initData: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      info: {},
      list: [],
      sonInfo: {},
      selectedOption: [],
    };
  },
  watch: {
    initData: {
      handler(val) {
        this.info = JSON.parse(JSON.stringify(val));
      },
      deep: true,
      immediate: true
    },
    'sonInfo.option': {
      handler(val) {
        let arr = JSON.parse(JSON.stringify(val)).filter(e => e.active)
        this.selectedOption = arr.map(i => {
          return {
            key: i.key,
            option: i.option,
            content: i.content,
            is_last: i.is_last
          }
        })
        if (this.list.length > 0) {
          let keys = this.selectedOption.map(e => e.key).join(',')
          this.$set(this.list[this.topicIndex], 'option', keys)
        }
      },
      deep: true
    },
    list: {
      handler(val) {
        if (val.length > 0) {
          this.$emit('countChange', this.count)
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    count() {
      // 整理列表中各选项的数量
      let a = this.list.filter(e => e.option * 1 === 1).length
      let b = this.list.filter(e => e.option * 1 === 2).length
      let c = this.list.filter(e => e.option * 1 === 3).length
      let d = this.list.filter(e => e.option.length === 0).length
      let arr = [
        { type: 1, name: '接受', num: a },
        { type: 2, name: '勉强接受', num: b },
        { type: 3, name: '抗拒', num: c },
        { type: 4, name: '未答', num: d },
      ]
      return arr
    },
    topicIndex() {
      return this.list.findIndex(e => e.topidrill_id * 1 === this.sonInfo.id * 1)
    }
  },
  created() {
    this.getTopicList();
    this.getTopicInfo(this.$route.query.topidrill_id);
  },
  methods: {
    // 获取子题目列表
    async getTopicList() {
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        lesson_id: this.$route.query.lesson_id,
        manor_id: this.$route.query.manor_id,
        main_id: this.$route.query.main_id,
        stage_id: this.$route.query.stage_id,
        kqtopic_id: this.$route.query.id,
      };
      let res = await this.$http.get('pad/oral-snag/son-num', { params });
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.list = res.data instanceof Array ? res.data : [];
    },
    // 获取子题目详细信息
    async getTopicInfo(id) {
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        kqtopic_id: this.$route.query.id,
        topidrill_id: id
      };
      let res = await this.$http.get('pad/oral-snag/son-topic', { params })
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.sonInfo = res.data;
      this.sonInfo.option.map((e, i) => {
        let flag = this.sonInfo.rec_info && this.sonInfo.rec_info.option && this.sonInfo.rec_info.option.length > 0 ? this.sonInfo.rec_info.option.some(i => i.key * 1 === e.key * 1) : false
        this.$set(e, 'active', flag)
      })
    },
    // 修改子题目评论状态
    commentStatusChange(val) {
      this.sonInfo.topscu_id = val
    },
    itemClick(item, index) {
      this.sonInfo.option.forEach(e => {
        e.active = false
      })
      this.$set(this.sonInfo.option[index], 'active', !item.active)
      this.$nextTick(() => {
        this.addRecord()
      })
    },
    // 子题目添加答题记录
    async addRecord() {
      let params = {
        id: this.sonInfo.rec_info ? this.sonInfo.rec_info.id : undefined,
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        trecord_id: this.$route.query.trecord_id,
        lesson_id: this.info.lesson_id,
        manor_id: this.info.manor_id,
        main_id: this.info.main_id,
        stage_id: this.info.stage_id,
        kqtopic_id: this.info.id,
        topidrill_id: this.sonInfo.id,
        option: JSON.stringify(this.selectedOption),
      }
      const res = await this.$http.post('kq-trectail/add', params)
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg)
      }
    },
    prev() {
      if (this.topicIndex > 0) {
        this.getTopicInfo(this.list[this.topicIndex - 1].topidrill_id)
        this.$router.replace({
          name: 'oralTopic',
          query: {
            uid: this.$route.query.uid,
            review_id: this.$route.query.review_id,
            ass_time: this.$route.query.ass_time,
            time: this.$route.query.time,
            lesson_id: this.$route.query.lesson_id,
            manor_id: this.$route.query.manor_id,
            main_id: this.$route.query.main_id,
            stage_id: this.$route.query.stage_id,
            id: this.$route.query.id,
            mailtrec_id: this.$route.query.mailtrec_id,
            trecord_id: this.$route.query.trecord_id,
            topidrill_id: this.list[this.topicIndex - 1].topidrill_id
          }
        })
      }
    },
    next() {
      if (this.topicIndex < this.list.length - 1) {
        if (this.sonInfo.is_must * 1 && this.selectedOption.length === 0) {
          return this.$vantnotify('本题目为必答题')
        }
        this.getTopicInfo(this.list[this.topicIndex + 1].topidrill_id)
        this.$router.replace({
          name: 'oralTopic',
          query: {
            uid: this.$route.query.uid,
            review_id: this.$route.query.review_id,
            ass_time: this.$route.query.ass_time,
            time: this.$route.query.time,
            lesson_id: this.$route.query.lesson_id,
            manor_id: this.$route.query.manor_id,
            main_id: this.$route.query.main_id,
            stage_id: this.$route.query.stage_id,
            id: this.$route.query.id,
            mailtrec_id: this.$route.query.mailtrec_id,
            trecord_id: this.$route.query.trecord_id,
            topidrill_id: this.list[this.topicIndex + 1].topidrill_id
          }
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  margin-bottom: 0.16rem;
}
.comment {
  position: fixed;
  bottom: 1.5rem;
  right: 0.25rem;
  font-size: 0.14rem;
  color: #ff7170;
  background: #fffbfb;
  width: 0.62rem;
  height: 0.62rem;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px #ffc4c4;
  border: 1px solid #ff8a88;
  text-align: center;
  padding: 0.11rem 0.15rem;
  cursor: pointer;
  box-sizing: border-box;
}
.video-occupy {
  width: 3.17rem;
  height: 1.79rem;
}
.options {
  margin-top: 0.5rem;
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  li {
    height: 0.34rem;
    line-height: 0.34rem;
    background: #ffffff;
    border-radius: 0.06rem;
    border: 1px solid #dfdfdf;
    padding: 0 0.18rem;
    font-size: 0.16rem;
    color: #666;
    box-sizing: border-box;
    margin: 0 0.08rem;
    cursor: pointer;
    &.active {
      background: #ffeeee;
      border: 1px solid #ffadac;
      color: #f14947;
    }
  }
}
.btns {
  text-align: center;
  margin-top: 0.3rem;
  button {
    font-size: 0.16rem;
    height: 0.38rem;
    border-radius: 0.19rem;
    padding: 0 0.36rem;
    margin: 0 0.1rem;
  }
}
</style>