<template>
  <div class="box">
    <div class="main-container">
      <span class="tag">{{ tag }}</span>
      <div class="title">{{ title }}</div>
    </div>
    <div class="tip" v-if="tip">
      <span>成功标准：</span>
      {{ tip }}
    </div>
    <template slot="other"></template>
    <div class="type" v-if="type">{{ ['','单选','多选'][type] }}</div>
  </div>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 0
    },
    tip: {
      type: String,
      default: ''
    }
  }

}
</script>

<style lang="less" scoped>
.box {
  background: #ffffff;
  border-radius: 0.06rem;
  padding: 0.13rem 0.4rem 0.15rem 0.15rem;
  line-height: 0.38rem;
  font-size: 0.16rem;
  position: relative;
  overflow: hidden;
  .main-container {
    overflow: hidden;
    margin-bottom: 0.1rem;
  }
  .tag {
    padding: 0 0.15rem;
    background: #ffaa20;
    border-radius: 0.06rem;
    margin-right: 0.15rem;
    color: #fff;
    float: left;
  }
  .title {
    color: #2b1a00;
  }
  .tip {
    background: #ffe9e9;
    border-radius: 0.25rem;
    border: 1px solid #ffcece;
    padding: 0 0.22rem;
    font-size: 0.14rem;
    color: #630200;
    span {
      font-size: 0.16rem;
      color: #fe6765;
    }
  }
  .type {
    width: 0.7rem;
    height: 0.7rem;
    background: #fe6765;
    text-align: center;
    line-height: 1.15rem;
    font-size: 0.12rem;
    color: #fff;
    position: absolute;
    top: -0.35rem;
    right: -0.35rem;
    transform: rotate(45deg);
  }
}
</style>