<template>
  <div class="com-video">
    <div class="preview-box" @click="showModalHandle">
      <img class="themeimg" :src="themeimg" />
      <div class="mask">
        <div class="text">
          <van-icon name="play-circle-o" />
          <p>示范视频</p>
        </div>
      </div>
    </div>
    <van-overlay :show="showModal" @click="showModalCancel">
      <div class="wrapper">
        <van-icon name="close" class="close" />
        <video style="width:50%;border-radius: 0.08rem;" :src="videoSrc" controls></video>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    themeimg: {
      type: String,
      default: require('@/assets/img/video.png'),
    },
  },
  data() {
    return {
      showModal: false,
      videoSrc: ''
    }
  },
  methods: {
    handleOk() {
      this.showModal = false
      this.videoSrc = '';
    },
    showModalHandle() {
      this.videoSrc = 'http://bjxxw-assets.oss-cn-beijing.aliyuncs.com/' + this.src;
      this.showModal = true
    },
    showModalCancel() {
      this.showModal = false
      this.videoSrc = '';
    },
  }
}
</script>

<style lang="less" scoped>
.com-video {
  display: inline-block;
  .preview-box {
    position: relative;
    border-radius: 0.08rem;
    font-size: 0;
    overflow: hidden;
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 0.14rem;
        color: #fff;
        text-align: center;
      }
      .van-icon {
        font-size: 0.35rem;
      }
      p {
        margin: 0.05rem 0 0;
      }
    }
  }

  .themeimg {
    width: 3.17rem;
    height: 1.79rem;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .close {
    font-size: 0.3rem;
    color: #fff;
    position: absolute;
    top: 10%;
    right: 10%;
  }
}
</style>