<template>
  <div>
    <my-title
      :tag="info.main_name + '-' + info.sta_name"
      :title="info.name"
      :tip="info.norm"
      :type="2"
    ></my-title>
    <my-video v-if="info.video_url" :src="info.video_url"></my-video>
    <div class="comment" @click="$emit('popupVisible')">{{ info.topscu_id > 0 ? '查看评论' : '添加评论'}}</div>
  </div>
</template>

<script>
import myTitle from "./title.vue";
import myVideo from "./video.vue";
export default {
  name: "multiple",
  components: {
    myTitle,
    myVideo
  },
  props: {
    initData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      info: {}
    };
  },
  watch: {
    initData: {
      handler(val) {
        this.info = JSON.parse(JSON.stringify(val));
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
}
</script>

<style lang="less" scoped>
.box {
  margin-bottom: 0.16rem;
}
.comment {
  position: fixed;
  bottom: 1.5rem;
  right: 0.25rem;
  font-size: 0.14rem;
  color: #ff7170;
  background: #fffbfb;
  width: 0.62rem;
  height: 0.62rem;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px #ffc4c4;
  border: 1px solid #ff8a88;
  text-align: center;
  padding: 0.11rem 0.15rem;
  cursor: pointer;
  box-sizing: border-box;
}
</style>