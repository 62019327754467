<template>
  <div class="record-container">
    <div class="record-header">
      <div class="text">
        <span>{{ directionTxt }}记录</span>
        <span class="num">
          {{ directionTxt }}记录有效次数：
          <span>{{ initObj[numKey] }}</span>次
        </span>
      </div>
    </div>
    <div class="record-body">
      <div class="item" v-for="(item, index) in initObj.list" :key="index">
        <span>{{item.key}}秒</span>
        <van-icon name="cross" @click="removeRecord(initObj, item, index, numKey)" />
      </div>
    </div>
    <div class="record-footer">
      <div class="time">{{ formatTime(recordTime) }}</div>
      <van-icon name="play" v-if="!isRunning" @click="startTimer" />
      <van-icon name="pause" v-else @click="stopTimer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "timeCountRecord",
  props: {
    initObj: {
      type: Object,
      default: () => { }
    },
    numKey: {
      type: String,
      default: ""
    },
    directionTxt: {
      type: String,
      default: ""
    },
    duration: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      timer: null,
      recordTime: 0,
      isRunning: false
    };
  },
  methods: {
    startTimer() {
      if (this.isRunning) return; // 如果已经在运行则直接返回
      this.recordTime = 0;
      this.isRunning = true;
      const startTime = new Date();
      this.timer = setInterval(() => {
        this.recordTime = Math.floor((new Date() - startTime) / 1000); // 更新计时时间（单位：秒）
      }, 1000);
      this.$emit('start', this);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.isRunning = false;
      this.addRecord(this.initObj, this.recordTime, this.numKey);
      this.recordTime = 0;
      // 发送事件给父组件，通知计时停止（如果需要的话）
      this.$emit('stop');
    },
    formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secondsRest = seconds % 60;
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secondsRest.toString().padStart(2, '0')}`;
    },
    addRecord(obj, key, numKey) {
      if (typeof obj !== 'object' || obj === null || !Array.isArray(obj.list)) {
        return;
      }
      obj.list.push({ key: key });
      if (key * 1 >= this.duration) {
        this.$set(obj, numKey, obj[numKey] + 1);
      }
      this.$emit('statusChange');
    },
    removeRecord(obj, item, index, numKey) {
      if (typeof obj !== 'object' || obj === null || !Array.isArray(obj.list)) {
        return;
      }
      obj.list.splice(index, 1)
      if (item.key * 1 >= this.duration) {
        this.$set(obj, numKey, obj[numKey] - 1);
      }
      this.$emit('statusChange');
    }
  }
}
</script>

<style lang="less" scoped>
.record-container {
  background: #fff;
  border-radius: 0.06rem;
  padding-bottom: 0.13rem;
  .record-header {
    display: flex;
    align-items: center;
    padding-right: 0.16rem;
    &::before {
      content: "";
      display: inline-block;
      width: 0.04rem;
      height: 0.18rem;
      background: #fe6765;
      border-radius: 0px 0.04rem 0.04rem 0px;
      margin-right: 0.12rem;
    }
    .text {
      border-bottom: 1px dashed #ffadac;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.16rem;
      color: #333;
      font-weight: bold;
      height: 0.48rem;
      box-sizing: border-box;
      .num {
        font-size: 0.14rem;
        font-weight: normal;
        color: #2b1a00;
        span {
          font-size: 0.18rem;
          color: #f14947;
        }
      }
    }
  }
  .record-body {
    padding: 0.12rem 0.16rem;
    display: flex;
    flex-wrap: wrap;
    .item {
      height: 0.32rem;
      background: #f9f9f9;
      border-radius: 0.04rem;
      border: 1px solid #ebebed;
      padding: 0 0.1rem;
      font-size: 0.14rem;
      color: #555;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin: 0 0.12rem 0.12rem 0;
      .van-icon {
        width: 0.16rem;
        height: 0.16rem;
        background: #fce5e5;
        border-radius: 50%;
        font-size: 0.1rem;
        color: #ff7170;
        text-align: center;
        line-height: 0.16rem;
        margin-left: 0.08rem;
      }
    }
  }
  .record-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.5rem;
    background: #fbf7f7;
    border-radius: 0.05rem;
    margin: 0 0.16rem;
    .time {
      height: 0.2rem;
      background: #fe6765;
      border-radius: 0.04rem;
      padding: 0 0.05rem;
      font-size: 0.14rem;
      color: #fff;
      line-height: 0.2rem;
    }
    .van-icon {
      width: 0.4rem;
      height: 0.4rem;
      background: #fff;
      border: 0.06rem solid #f0eff4;
      border-radius: 50%;
      box-sizing: border-box;
      font-size: 0.18rem;
      color: #fe6765;
      text-align: center;
      line-height: 0.28rem;
      margin-left: 0.2rem;
    }
  }
}
</style>