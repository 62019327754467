<template>
  <div class="oral-topic_page">
    <van-nav-bar left-arrow fixed left-text="返回" @click-left="$router.go(-1)">
      <template #right>
        <div v-if="$route.query.topidrill_id > 0">
          <div class="exerciseCount">
            <div class="item" v-for="(item, index) in exerciseCount" :key="item.type">
              <i
                class="iconfont"
                :class="['icon-accept','icon-a-settlefor','icon-resist','icon-Unanswered'][index]"
              ></i>
              <span>
                {{ item.name }}
                <span class="num">{{ item.num }}</span>个
              </span>
            </div>
          </div>
        </div>
        <van-button color="#F14947" v-else @click="finish">完成评估</van-button>
      </template>
    </van-nav-bar>
    <div class="container">
      <exercise
        v-if="info.type * 1 === 1 && !$route.query.topidrill_id"
        :initData="info"
        @countChange="countChange"
      ></exercise>
      <son-topic
        ref="sontopic"
        v-if="info.type * 1 === 1 && $route.query.topidrill_id > 0"
        :initData="info"
        @countChange="countChange"
        @popupVisible="popupVisible"
      ></son-topic>
      <single v-if="info.type * 1 === 2" :initData="info" @popupVisible="popupVisible"></single>
      <counting
        ref="counting"
        v-if="info.type * 1 === 3"
        :initData="info"
        @popupVisible="popupVisible"
        @statusChange="statusChange"
      ></counting>
      <time-counting
        ref="timecounting"
        v-if="info.type * 1 === 4"
        :initData="info"
        @popupVisible="popupVisible"
        @statusChange="statusChange"
      ></time-counting>
      <multiple v-if="info.type * 1 === 5" :initData="info" @popupVisible="popupVisible"></multiple>
    </div>
    <my-popup v-if="popupShow" width="70%" ref="popupmodal" @popupClose="popupClose"></my-popup>
    <my-footer
      :options="info.option"
      :isFirst="info.is_up * 1 === 1 ? false : true"
      :type="info.type * 1 === 5 || (info.type * 1 === 1 && info.is_duo == 1) ? 2 : 1"
      @optionChange="optionChange"
      @prev="prev"
      @next="next"
    ></my-footer>
  </div>
</template>

<script>
import exercise from "./components/exercise.vue";
import single from "./components/single.vue";
import multiple from "./components/multiple.vue";
import counting from "./components/counting.vue";
import timeCounting from "./components/timeCounting.vue";
import sonTopic from "./components/sonTopic.vue";
import myPopup from "./components/popup.vue";
import myFooter from "./components/footer.vue";
export default {
  name: "topic",
  components: {
    exercise,
    single,
    multiple,
    counting,
    timeCounting,
    sonTopic,
    myPopup,
    myFooter
  },
  data() {
    return {
      info: {},
      popupShow: false,
      selectedOption: [],
      exerciseCount: []
    }
  },
  created() {
    this.initData()
  },
  watch: {
    $route(to, from) {
      if (to.path === '/oralTopic' && !to.query.topidrill_id) {
        this.initData()
      }
    },
  },
  methods: {
    async initData() {
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        lesson_id: this.$route.query.lesson_id,
        manor_id: this.$route.query.manor_id,
        main_id: this.$route.query.main_id,
        stage_id: this.$route.query.stage_id,
        kqtopic_id: this.$route.query.id,
      }
      let res = await this.$http.get('pad/oral-snag/topic-get', { params })
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.info = res.data
      this.info.option.map(e => {
        let flag = this.info.rec_info.option && this.info.rec_info.option.length > 0 ? this.info.rec_info.option.some(i => i.key * 1 === e.key * 1) : false
        this.$set(e, 'active', flag)
      })
    },
    // 打开评论弹窗
    async popupVisible() {
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        kqtopic_id: this.info.id,
        is_son: this.$route.query.topidrill_id > 0 ? 1 : 0,
        topdril_id: this.$route.query.topidrill_id
      }
      let res = await this.$http.get('kq-topdiscu/get', { params })
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      let content = res.data.discuss;
      this.popupShow = true;
      this.$nextTick(() => {
        this.$refs.popupmodal.dataForm = {
          id: res.data.id,
          content
        };
        this.$refs.popupmodal.init();
      });
    },
    // 关闭评论弹窗
    async popupClose(val) {
      if (val) {
        let params = {
          id: val.id,
          uid: this.$route.query.uid,
          review_id: this.$route.query.review_id,
          lesson_id: this.info.lesson_id,
          manor_id: this.info.manor_id,
          main_id: this.info.main_id,
          stage_id: this.info.stage_id,
          type: this.info.type,
          kqtopic_id: this.info.id,
          is_son: this.$route.query.topidrill_id > 0 ? 1 : 0,
          topdril_id: this.$route.query.topidrill_id,
          discuss: val.content,
        }
        const res = await this.$http.post('kq-topdiscu/add', params)
        if (res.code * 1 !== 1) {
          return this.$vantnotify(res.msg)
        }
        if (this.$route.query.topidrill_id > 0) {
          this.$refs.sontopic.commentStatusChange(res.data.id)
        } else {
          this.info.topscu_id = res.data.id
        }
      }
      this.popupShow = false;
    },
    // 选项变化
    optionChange(val) {
      let arr = JSON.parse(JSON.stringify(val)).filter(e => e.active)
      this.selectedOption = arr.map(i => {
        return {
          key: i.key,
          option: i.option,
          content: i.content,
          is_last: i.is_last
        }
      })
    },
    // 计次/计时题状态变化
    statusChange(isPass) {
      this.info.option.forEach(e => {
        e.active = false
        if ((isPass && e.key * 1 === 1) || (!isPass && e.key * 1 === 2)) {
          e.active = true
        }
      })
    },
    // 计次/计时题组合记录数据
    getSubmitData(refName) {
      let arr = undefined
      switch (this.info.seat_type * 1) {
        case 1:
          arr = this.$refs[refName] ? this.$refs[refName].singleData : undefined;
          break;
        case 2:
          arr = this.$refs[refName] ? this.$refs[refName].dobleData : undefined;
          break;
        case 3:
          arr = this.$refs[refName] ? this.$refs[refName].threeData : undefined;
          break;
        default:
          break;
      }
      return JSON.stringify(arr)
    },
    // 上一题
    async prev(val) {
      //   console.log(val);
      if (this.info.rec_info.id && this.selectedOption.length === 0) {
        return this.$vantnotify('请作答本题目')
      }
      if (this.info.rec_info.id) {
        this.addRecord(1)
      } else {
        this.jump(1)
      }
    },
    // 下一题
    async next(val) {
      if (this.selectedOption.length === 0) {
        return this.$vantnotify('请作答本题目')
      }
      this.addRecord(2)
    },
    // 添加答题记录
    async addRecord(type) {
      let params = {
        id: this.info.rec_info ? this.info.rec_info.id : undefined,
        uid: this.$route.query.uid,
        mailtrec_id: this.$route.query.mailtrec_id,
        review_id: this.$route.query.review_id,
        lesson_id: this.info.lesson_id,
        manor_id: this.info.manor_id,
        main_id: this.info.main_id,
        stage_id: this.info.stage_id,
        num: this.info.num,
        kqtopic_id: this.info.id,
        type: this.info.type,
        seat_type: this.info.seat_type,
        option: this.selectedOption.length > 0 ? JSON.stringify(this.selectedOption) : '',
        s_type: type === 1 ? 1 : 0
      }
      if (this.info.type * 1 === 1) {
        params.train_rec = JSON.stringify(this.exerciseCount)
        let params1 = {
          uid: this.$route.query.uid,
          mailtrec_id: this.$route.query.mailtrec_id,
          review_id: this.$route.query.review_id,
          lesson_id: this.info.lesson_id,
          manor_id: this.info.manor_id,
          main_id: this.info.main_id,
          stage_id: this.info.stage_id,
          num: this.info.num,
          kqtopic_id: this.info.id,
          type: this.info.type,
        }
        const res1 = await this.$http.post('kq-trecord/initial', params1);
        if (res1.code * 1 !== 1) {
          return this.$vantnotify(res1.msg);
        }
        params.id = res1.data.id
      }
      if (this.info.type * 1 === 3) {
        params.train_rec = this.getSubmitData('counting')
      }
      if (this.info.type * 1 === 4) {
        params.train_rec = this.getSubmitData('timecounting')
      }
      const res = await this.$http.post('kq-trecord/add', params)
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg)
      }
      this.jump(type)
    },
    // 跳转
    async jump(type) {
      // type 1:上一题 2:下一题
      if (type === 1) {
        let params = {
          uid: this.$route.query.uid,
          review_id: this.$route.query.review_id,
          lesson_id: this.info.lesson_id,
          manor_id: this.info.manor_id,
          main_id: this.info.main_id,
          stage_id: this.info.stage_id,
          dow_num: this.info.num
        }
        const res = await this.$http.get('pad/oral-snag/up-topic', { params })
        if (res.code * 1 !== 1) {
          return this.$vantnotify(res.msg)
        }
        Object.assign(this.$data, this.$options.data())
        this.$router.replace({
          name: 'oralTopic',
          query: {
            uid: this.$route.query.uid,
            review_id: this.$route.query.review_id,
            ass_time: this.$route.query.ass_time,
            time: this.$route.query.time,
            lesson_id: res.data.lesson_id,
            manor_id: res.data.manor_id,
            main_id: res.data.main_id,
            stage_id: res.data.stage_id,
            id: res.data.id,
            mailtrec_id: this.$route.query.mailtrec_id
          }
        })
      }
      if (type === 2) {
        let params = {
          uid: this.$route.query.uid,
          review_id: this.$route.query.review_id,
          lesson_id: this.info.lesson_id,
          manor_id: this.info.manor_id,
          main_id: this.info.main_id,
          stage_id: this.info.stage_id,
          up_num: this.info.num
        }
        const res = await this.$http.get('pad/oral-snag/dow-topic', { params })
        if (res.code * 1 !== 1) {
          return this.$vantnotify(res.msg)
        }
        if (res.data.status * 1 === 1 || res.data.status * 1 === 2) {
          this.finish()
        } else {
          Object.assign(this.$data, this.$options.data())
          this.$router.replace({
            name: 'oralTopic',
            query: {
              uid: this.$route.query.uid,
              review_id: this.$route.query.review_id,
              ass_time: this.$route.query.ass_time,
              time: this.$route.query.time,
              lesson_id: res.data.info.lesson_id,
              manor_id: res.data.info.manor_id,
              main_id: res.data.info.main_id,
              stage_id: res.data.info.stage_id,
              id: res.data.info.id,
              mailtrec_id: this.$route.query.mailtrec_id
            }
          })
        }
      }
    },
    countChange(val) {
      this.$set(this, 'exerciseCount', val)
    },
    // 本维度完成评估
    async finish() {
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        lesson_id: this.$route.query.lesson_id,
        manor_id: this.$route.query.manor_id,
        main_id: this.$route.query.main_id,
      }
      const res = await this.$http.post('pad/oral-snag/main-fin', params);
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      if (res.data.status * 1 === 1) {
        return this.$toast.success({
          duration: 3000,
          forbidClick: true,
          message: "评估完成",
          onClose: () => {
            this.$router.replace({
              name: "oralSnag",
              query: {
                uid: this.$route.query.uid,
                review_id: this.$route.query.review_id,
                ass_time: this.$route.query.ass_time,
                time: this.$route.query.time,
              },
            }).then(() => {
              this.$router.go(-1)
            });
          },
        });
      } else if (res.data.status * 1 === 2) {
        this.$dialog
          .alert({
            title: '提示',
            message: `${this.info.main_name}测评已结束，进入<span>${res.data.main_name}</span>测评`,
            theme: 'round-button',
            confirmButtonText: '确定',
            confirmButtonColor: "#FA4B52",
            getContainer: ".oral-topic_page",
          })
          .then(() => {
            this.$router.replace({
              name: "criterion",
              query: {
                uid: this.$route.query.uid,
                review_id: this.$route.query.review_id,
                ass_time: this.$route.query.ass_time,
                time: this.$route.query.time,
                lesson_id: res.data.lesson_id,
                manor_id: res.data.manor_id,
                main_id: res.data.main_id,
                main_name: res.data.main_name
              },
            });
          })
          .catch((res) => {
            console.log(res);
          });
      }
    }
  }
}
</script>

<style lang="less" scoped>
.oral-topic_page {
  background: url(~@/assets/img/bj.png) top left/100% auto no-repeat #f8f8f8;
  height: 100vh;
  padding-top: 0.46rem;
  box-sizing: border-box;
  button {
    border: none;
  }
  .van-nav-bar {
    background: rgba(255, 255, 255, 0.3);
    &::after {
      border: none;
    }
    /deep/ .van-nav-bar__content {
      height: 0.46rem;
    }
    /deep/ .van-nav-bar__left {
      font-size: 0.14rem;
      .van-nav-bar__text {
        color: #666;
      }
      .van-icon {
        font-size: 0.14rem;
        color: #666;
        margin: 0;
      }
    }
    /deep/ .van-nav-bar__right {
      button {
        font-size: 0.14rem;
        height: 0.34rem;
        border-radius: 0.06rem;
      }
    }
  }
  .exerciseCount {
    height: 0.32rem;
    line-height: 0.32rem;
    background: #ffffff;
    border-radius: 0.06rem;
    border: 1px solid #ffdcdc;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .item {
      padding-left: 0.13rem;
      display: flex;
      align-items: center;
      font-size: 0.14rem;
      .iconfont {
        margin-right: 0.03rem;
        font-size: 0.18rem;
      }
      .num {
        font-size: 0.18rem;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 0.23rem;
        background: linear-gradient(
          244deg,
          rgba(204, 204, 204, 0) 0%,
          rgba(255, 206, 197, 1) 50%,
          rgba(204, 204, 204, 0) 100%
        );
        margin-left: 0.13rem;
      }
      &:nth-child(1) {
        color: #4bb931;
      }
      &:nth-child(2) {
        color: #ff7c24;
      }
      &:nth-child(3) {
        color: #f14947;
      }
      &:last-child {
        padding-right: 0.13rem;
        color: #333;
        &::after {
          display: none;
        }
      }
    }
  }
  .container {
    padding: 0.15rem 0.2rem 1.5rem;
    height: calc(100vh - 0.46rem);
    overflow-y: scroll;
    box-sizing: border-box;
  }
  /deep/ .van-dialog {
    font-size: 0.18rem;
    background: linear-gradient(180deg, #ffeeee 0%, #ffffff 100%);
    border-radius: 0.16rem;
    overflow: visible;
    .van-dialog__header {
      padding-top: 0.55rem;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 0.64rem;
        height: 0.62rem;
        background: url("~@/assets/img/topic_msg.png") 0 0/100% no-repeat;
        position: absolute;
        top: -30%;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .van-dialog__message {
      font-size: 0.15rem;
      color: #666;
      span {
        color: #fa4b52;
      }
    }
    .van-dialog__footer {
      background: none;
      justify-content: center;
      button {
        width: 0.86rem;
        height: 0.34rem;
        border-radius: 0.06rem;
        font-size: 0.16rem;
        flex: none;
      }
    }
  }
}
</style>