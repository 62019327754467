<template>
  <div class="record-container">
    <div class="record-header">
      <div class="text">
        <span>{{ directionTxt }}记录</span>
        <span class="num">
          {{ directionTxt }}记录有效次数：
          <span>{{ initObj[numKey] }}</span>次
        </span>
      </div>
    </div>
    <div class="record-body">
      <div class="item" v-for="(item, index) in initObj.list" :key="index">
        <span>{{optionLabels[item.key]}}</span>
        <van-icon name="cross" @click="removeRecord(initObj, item, index, numKey)" />
      </div>
    </div>
    <div class="record-footer">
      <van-button
        color="#FE6765"
        round
        v-for="(item,ind) in optionLabels"
        :key="item"
        @click="addRecord(initObj, ind, numKey)"
      >{{ item }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "countRecord",
  props: {
    initObj: {
      type: Object,
      default: () => { }
    },
    numKey: {
      type: String,
      default: ""
    },
    directionTxt: {
      type: String,
      default: ""
    },
    objTxt: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      optionLabels: ['不可以', '可以'],
    };
  },
  methods: {
    addRecord(obj, key, numKey) {
      if (typeof obj !== 'object' || obj === null || !Array.isArray(obj.list)) {
        return;
      }
      obj.list.push({ key: key, name: this.optionLabels[key] });
      if (key === 1) {
        this.$set(obj, numKey, obj[numKey] + 1);
      }
      this.$emit('statusChange');
    },
    removeRecord(obj, item, index, numKey) {
      if (typeof obj !== 'object' || obj === null || !Array.isArray(obj.list)) {
        return;
      }
      obj.list.splice(index, 1)
      if (item.key * 1 === 1) {
        this.$set(obj, numKey, obj[numKey] - 1);
      }
      this.$emit('statusChange', this.objTxt, obj);
    }
  }
}
</script>

<style lang="less" scoped>
.record-container {
  background: #fff;
  border-radius: 0.06rem;
  padding-bottom: 0.13rem;
  .record-header {
    display: flex;
    align-items: center;
    padding-right: 0.16rem;
    &::before {
      content: "";
      display: inline-block;
      width: 0.04rem;
      height: 0.18rem;
      background: #fe6765;
      border-radius: 0px 0.04rem 0.04rem 0px;
      margin-right: 0.12rem;
    }
    .text {
      border-bottom: 1px dashed #ffadac;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.16rem;
      color: #333;
      font-weight: bold;
      height: 0.48rem;
      box-sizing: border-box;
      .num {
        font-size: 0.14rem;
        font-weight: normal;
        color: #2b1a00;
        span {
          font-size: 0.18rem;
          color: #f14947;
        }
      }
    }
  }
  .record-body {
    padding: 0.12rem 0.16rem;
    display: flex;
    flex-wrap: wrap;
    .item {
      height: 0.32rem;
      background: #f9f9f9;
      border-radius: 0.04rem;
      border: 1px solid #ebebed;
      padding: 0 0.1rem;
      font-size: 0.14rem;
      color: #555;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin: 0 0.12rem 0.12rem 0;
      .van-icon {
        width: 0.16rem;
        height: 0.16rem;
        background: #fce5e5;
        border-radius: 50%;
        font-size: 0.1rem;
        color: #ff7170;
        text-align: center;
        line-height: 0.16rem;
        margin-left: 0.08rem;
      }
    }
  }
  .record-footer {
    display: flex;
    justify-content: center;
    button {
      width: 0.8rem;
      height: 0.3rem;
      margin: 0 0.1rem;
      font-size: 0.15rem;
    }
  }
}
</style>